
import { useState } from 'react';

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { TitleBar } from '../components/TitleBar'

import './MainPage.css'
import { NavigationBar } from '../components/NavigationBar';
import { HomePage } from './HomePage';
import { ProductsPage } from './ProductsPage';
import { AboutPage } from './AboutPage';
import { Page } from '../types/Page';

interface MainPageProps {
  
}

export const MainPage = (props: MainPageProps) => {
  const [page, setPage] = useState<Page>(Page.Home)

  return (
    <div className="MainPage">
      <div className="Content">
        <TitleBar/>
        <Header/>
        <NavigationBar page = { page } onChangePage={ setPage }/>
        <div className="Spacer"/>
        { page === Page.Home && <HomePage/> }
        { page === Page.Products && <ProductsPage/> }
        { page === Page.About && <AboutPage/> }
        <Footer/>
      </div>
    </div>
  )
};
