import './HomePage.css'

import { loadPosts } from '../data/Posts'
import { PostCard } from '../components/PostCard';

interface HomePageProps {

}

export const HomePage = (props: HomePageProps) => {
  const posts = loadPosts().map((post) => {
    return (
      <PostCard post={post}/>
    )
  });


  return (
    <div className="HomePage">
      <h1>montatranka</h1>
      <p>Willkommen bei montatranka</p>

      <p/>
      <h2>Aktuell</h2>
      {posts}
      <p></p>
    </div>
  )
}