import { Post } from '../data/Post';

import './PostCard.css'

interface PostCardParams {
  post: Post;
}

export const PostCard = (params: PostCardParams) => {
  const { post } = params;
  const { image, text, timestamp } = post;

  const dateString = `${timestamp.getDate()}.${timestamp.getMonth() + 1}.${timestamp.getFullYear()}`;
  const timestampString = `${dateString}`;

  const textContent = text.map((entry) => (<p>{ entry }</p>));

  return (
    <div className="PostCard">
      <div className="PostCardHeader">
        {timestampString}
      </div>
      <div className="PostCardBody">
        {image && <img className="PostImageLeft"
          { ...image }
          alt={ image?.alt }
          />
        }
        <p>
          {textContent}
        </p>
      </div>
    </div>
  )
}
