import { Page } from '../types/Page';
import './../styles/common.css';
import './NavigationBar.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface NavigationBarProps {
  page: Page;
  onChangePage: (newPage: Page) => void;
}


export const NavigationBar = (props: NavigationBarProps) => {
  const { page, onChangePage } = props;

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTab = (event: React.SyntheticEvent, newValue: Page) => {
    onChangePage(newValue);
  };

  return (
    <div className="MainNavigation">
      <div className="NavigationLeft">
        
      
          <Tabs
            variant='standard'
            value={page}
            onChange={handleTab}
            aria-label="main-tab control"
            textColor="secondary"
            className='Tabs'
            >
            <Tab label="Home" {...a11yProps(0)} />
            <Tab label="Produkte" {...a11yProps(1)} />
            <Tab label="Kontakt" {...a11yProps(2)} />
          </Tabs>
      </div>

    </div>
  )
}

/*
      <div className="NavigationLeft">
        <Button
          
          onClick={ () => onChangePage(Page.Home) }
          >
          Home
        </Button>
        <Button
          onClick={ () => onChangePage(Page.Products) }
          >
          Products
        </Button>
      </div>

      <div className="NavigationRight">
        <Button onClick={() => {}}>About</Button>
      </div>
*/
