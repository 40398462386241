import './ProductsPage.css'

import t1 from '../assets/images/t1.png'
import t2 from '../assets/images/t2.png'
import s1 from '../assets/images/s1.png'
import s2 from '../assets/images/s2.png'


interface ProductPageProps {

}

interface ProductProps {
  src: string;
  alt?: string;
}

const Product = (props: ProductProps) => {
  const { src, alt } = props;
  return (
    <div className="Product">
      <img
        src={src} alt={alt ?? 'product'}
        width="200px"
        height="200px"
        />
    </div>
  )
}

interface ProductsProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

const Products = (props: ProductsProps) => {
  const { title, children } = props;
  return (
    <div>
      <h2>{title}</h2>

      <div className='Products'>
        {children}
      </div>
    </div>
  )
}

export const ProductsPage = (props: ProductPageProps) => {
  return (
    <div className='ProductsPage'>
      <h1>Aktuelle Produkte</h1>

      <Products title='Taschen'>
        <Product src={t1} alt='tasche'/>
        <Product src={t2} alt='tasche'/>
      </Products>    

      <Products title='Stirnbänder, Kappen'>
        <Product src={s1} alt='stirnbänder'/>
        <Product src={s2} alt='stirnbänder'/>
      </Products>
    </div>
  )
}
