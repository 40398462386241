import './TitleBar.css'

interface TitleBarProps {

}

export const TitleBar = (props: TitleBarProps) => {
  return (
    <div className="TitleBar">

    </div>
  )
}