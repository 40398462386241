import './App.css';

import { AppRoot } from './pages/AppRoot';

function App() {
  return (
    <div className="App">
      <AppRoot/>
    </div>
  );
}

export default App;
