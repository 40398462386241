import { ThemeProvider, createTheme } from "@mui/material"
import { MainPage } from "./MainPage"

export const AppRoot = () => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5CA9CE',
        // main: '#ff0000',
      },
      secondary: {
        main: '#492A17',
      },
    },
    
  });
  

  return (
    <div className="root">
      <ThemeProvider theme={theme}>
        <MainPage/>
      </ThemeProvider>
      
    </div>
  )
}
