import Button from '@mui/material/Button'

import instagram from '../resources/Instagram_Glyph_Gradient.png';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import './AboutPage.css'

interface AboutPageProps {

}

const USER = 'montatranka';
const DOMAIN = 'montatranka.ch';

const setupMail = () => {
   window.location.href =`mailto:${USER}@${DOMAIN}`;
}

export const AboutPage = (props: AboutPageProps) => {
  
  return (
    <div className="AboutPage">
      <h1>Kontakt</h1>
      <p>
        montatranka<br/>
        Soligänterstr. 31<br/>
        CH-8180 Bülach
      </p>
      <p>
      <Button
        variant='contained'
        onClick={ () => { setupMail(); }}
        >
        <EmailOutlinedIcon/>
      </Button>
      </p>
      <p>
        <a href="https://www.instagram.com/montatranka/">
          <Button variant='contained'>
            Mehr auf Instagram
            <img
              className="InstagramLogo"
              src={ instagram } alt={ 'Instagram '}
              width="60px"
              />
          </Button>
        </a>
      </p>
    </div>
  )
}
