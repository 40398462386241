import './Header.css'

import header from '../resources/header.png'

interface HeaderProps {

}

export const Header = (props: HeaderProps) => {
  return (
    <div className="Header">
      <img src={header} alt='montatranka'
        width="100%"
        />
      <div className="HeaderTitle">montatranka</div>
    </div>
  )
}

//<img src={header} alt="Logo"/>
