interface FooterProps {

}

export const Footer = (props: FooterProps) => {
  return (
    <div className="Footer">
      
    </div>
  )
}