import { Post } from "./Post";

import weihnachtsmarkt2023 from './../assets/images/weihnachtsmarkt2023.png'
import weihnachtsmarkt2023_1 from './../assets/images/weihnachtsmarkt2023-1.png'

export const loadPosts = (): Post[] => (
  [{
    image: {
      src: weihnachtsmarkt2023_1,
      alt: 'Weihnachtsmarkt Bachenbülach Impressionen',
      maxWidth: '300px',
      width: '30%',
    },
    text: [
      'Weihnachtsmarkt-Stimmung am montatranka Stand in Bachenbülach',
    ],
    timestamp: new Date(2023, 11, 10, 11, 30, 0),
  }, {
    image: {
      src: weihnachtsmarkt2023,
      alt: 'Weihnachtsmarkt Bachenbülach',
      maxWidth: '300px',
      maxHeight: '300px',
      width: '30%',
      height: '30%',
    },
    text: [
      '2. Dezember 2023:',
      'montatranka am Weihnachtsmarkt in Bachenbülach'
    ],
    timestamp: new Date(2023, 10, 30, 10, 0, 0),
  }]
);
